import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Container } from '../components/Container';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';
import Paragraph from '../components/Paragraph/Paragraph';
import { seo } from '../components/Seo';
import ServiceCard from '../components/ServiceCard/ServiceCard';
import services from '../data/services';

export const Head = seo({ title: 'Home' });

export default function IndexPage() {
  const intl = useIntl();

  return (
    <Page>
      <Container pt={[2, 8]}>
        <Stack spacing={8} overflow="auto" pb={14}>
          <Stack>
            <GradientText>{intl.formatMessage({ id: 'hello' })}</GradientText>
            <Box>
              <Paragraph>{intl.formatMessage({ id: 'intro' })}</Paragraph>
            </Box>
          </Stack>
          <SimpleGrid columns={[2, null, 3]} spacing="40px">
            {services.map((service) => (
              <ServiceCard service={service} key={service.id} />
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Page>
  );
}
