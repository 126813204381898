/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Card, CardBody, CardFooter } from '@chakra-ui/card';
import { Button, Divider, Heading, Stack, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { DEFAULT_IMAGE_PATH } from '../../constants';
import { QueryResult } from '../../queries/queries-types';
import { Service } from '../../types/Service';

export interface ServiceCardProps {
  service: Service;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
  const intl = useIntl();

  const data = useStaticQuery<QueryResult>(graphql`
    query {
      allFile(filter: { extension: { regex: "/(webp)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `);

  const images: Record<string, IGatsbyImageData> = data.allFile.edges.reduce(
    (imgMap: Record<string, IGatsbyImageData>, edge) => {
      // eslint-disable-next-line no-param-reassign
      imgMap[edge.node.base] = edge.node.childImageSharp.gatsbyImageData;
      return imgMap;
    },
    {},
  );

  const image: IGatsbyImageData = images[service.imagePath || DEFAULT_IMAGE_PATH];

  return (
    <Card maxW="sm">
      <CardBody>
        <GatsbyImage image={getImage(image)!} alt={service.key} />
        <Stack mt="6" spacing="3">
          <Heading size="md">{intl.formatMessage({ id: `services.${service.key}.name` })}</Heading>
          <Text>{intl.formatMessage({ id: `services.${service.key}.description` })}</Text>
          <Text color="blue.600" fontSize="2xl">
            {service.averagePrice}
          </Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Button variant="link" colorScheme="blue">
          {intl.formatMessage({ id: 'buttons.moreInfo' })}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ServiceCard;
