import { Service } from '../types/Service';

const services: Service[] = [
  {
    id: '1',
    key: 'webDevelopment',
    imagePath: 'webDevelopment.webp',
  },
  {
    id: '2',
    key: 'appDevelopment',
    imagePath: 'appDevelopment.webp',
  },
  {
    id: '3',
    key: 'uiUxDesign',
    imagePath: 'uiUxDesign.webp',
  },
  {
    id: '4',
    key: 'branding',
    imagePath: 'branding.webp',
  },
  {
    id: '5',
    key: 'seoServices',
    imagePath: 'seoServices.webp',
  },
  {
    id: '6',
    key: 'eCommerceSolutions',
    imagePath: 'eCommerceSolutions.webp',
  },
];

export default services;
