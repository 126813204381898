import { Text, useColorModeValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { TEXT_COLOR_DARK, TEXT_COLOR_LIGHT } from '../../styles/colors';

interface ParagraphProps {
  children: ReactNode;
  maxWidth?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ children, maxWidth = '650px' }) => {
  const color = useColorModeValue(TEXT_COLOR_DARK, TEXT_COLOR_LIGHT);

  return (
    <Text
      textAlign="center"
      pt={8}
      pb={8}
      fontSize={['md', 'lg', 'xl', '2xl']}
      color={color}
      fontFamily="'Poppins', sans-serif"
      lineHeight="tall"
      maxWidth={maxWidth}
      mx="auto"
      borderRadius="xl"
    >
      {children}
    </Text>
  );
};

export default Paragraph;
